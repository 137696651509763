<div class="container">
    <div *ngFor="let info of partnerInfo" class="partner-info">
        <img
            class="partner-logo"
            [class.full-size]="info.fullSizeImage"
            [class.square-logo]="info.squareImage"
            [src]="info.imageUrl"
            alt="image" />
        <div class="partner-name text-color">
            <a [href]="info.url" target="_blank">{{ info.abbreviation }}</a>
            <span> - {{ info.name }}</span>
        </div>
        <p class="text-color description">{{ info.description }}</p>
    </div>
</div>
