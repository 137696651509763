import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PartnersComponent} from './components/partners/partners.component';
import {ReleaseNotesComponent} from './components/release-notes/release-notes.component';
import {AboutComponent} from './components/about/about.component';

const routes: Routes = [
    {
        path: 'partners',
        component: PartnersComponent,
        data: {title: 'Partners', useNewDesign: true},
    },
    {
        path: 'releasenotes',
        component: ReleaseNotesComponent,
        data: {
            title: 'Release Notes',
            useNewDesign: true,
        },
    },
    {
        path: 'about',
        component: AboutComponent,
        data: {title: 'About', useNewDesign: true},
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HelpRoutingModule {}
