import {Component, OnInit} from '@angular/core';

interface PartnerInfo {
    name: string;
    abbreviation: string;
    url: string;
    imageUrl: string;
    squareImage?: boolean;
    fullSizeImage?: boolean;
    description: string;
}

@Component({
    selector: 'eaglei-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent {
    public readonly partnerInfo: PartnerInfo[] = [
        {
            abbreviation: 'ORNL',
            name: 'Oak Ridge National Laboratory',
            url: 'https://www.ornl.gov/',
            imageUrl: 'dist/browser/images/partners/ornl-logo-big.png',
            description: 'The Oak Ridge National Laboratory hosts and operates the EAGLE-I™ system for the U.S. Department of Energy.',
        },
        {
            abbreviation: 'FEMA',
            name: 'Federal Emergency Management Agency',
            url: 'https://www.fema.gov/',
            imageUrl: 'dist/browser/images/partners/fema.png',
            description:
                'FEMA’s mission is to support our citizens and first responders to ensure that as a nation we work ' +
                'together to build, sustain and improve our capability to prepare for, protect against, respond ' +
                'to, recover from and mitigate all hazards.',
        },
        {
            abbreviation: 'NCC',
            name: 'National Coordinating Center for Communications',
            url: 'https://www.dhs.gov/national-coordinating-center-communications',
            squareImage: true,
            imageUrl: 'dist/browser/images/partners/dhs-250x250.png',
            description:
                `As part of the Department of Homeland Security's (DHS) National Cybersecurity and Communications Integration Center ` +
                `(NCCIC), the National Coordinating Center for Communications (NCC) continuously monitors national and international incidents and ` +
                `events that may impact emergency communications. Incidents include not only acts of terrorism, but also natural events such as ` +
                `tornadoes, floods, hurricanes and earthquakes. In cases of emergency, NCC Watch leads emergency communications response and ` +
                `recovery efforts under Emergency Support Function #2 of the National Response Framework.`,
        },
        {
            abbreviation: 'ANL',
            name: 'Argonne National Lab',
            url: 'https://www.anl.gov/',
            imageUrl: 'dist/browser/images/partners/anl_logo.png',
            description:
                'As part of the Department of Energy, ANL provides support during Hurricanes and other natural disasters. Output from the ' +
                'HEADOUT model developed and supported by ANL has been integrated into EAGLE-I™.',
        },
        {
            abbreviation: 'PNNL',
            name: 'Pacific Northwest National Lab',
            url: 'https://www.pnnl.gov/',
            imageUrl: 'dist/browser/images/partners/pnnl-logo.png',
            description:
                'As part of the Department of Energy, PNNL provides support during Hurricanes and other natural disasters. Results from ' +
                'multiple damage assessment algorithms have been integrated into EAGLE-I™.',
        },
        {
            abbreviation: 'HAMMER',
            name: 'Hazardous Materials Management and Emergency Response',
            url: 'https://hammer.hanford.gov/page.cfm/ConstructionWorkerSafetyTrainingProgram',
            imageUrl: 'dist/browser/images/partners/hammer-logo.png',
            description:
                'The HAMMER team works closely with ESF #12 responders and frequently provides informative feedback ' +
                'and suggestions that help to shape the EAGLE-I interface, analytics, and acronyms.',
        },
    ];

    constructor() {}
}
