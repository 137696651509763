import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HelpRoutingModule} from './help-routing.module';
import {PartnersComponent} from './components/partners/partners.component';
import {ReleaseNotesComponent} from './components/release-notes/release-notes.component';
import {SharedModule} from '../../../shared/shared.module';
import {UserFeedbackComponent} from './modals/user-feedback/user-feedback.component';
import {AboutComponent} from './components/about/about.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [PartnersComponent, ReleaseNotesComponent, UserFeedbackComponent, AboutComponent],
    imports: [SharedModule, CommonModule, HelpRoutingModule, FontAwesomeModule],
    exports: [UserFeedbackComponent],
})
export class HelpModule {}
