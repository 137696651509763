import {Component, OnInit} from '@angular/core';
import {HelpService} from '../../services/help.service';
import {Router} from '@angular/router';

@Component({
    selector: 'eaglei-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
    constructor(private helpService: HelpService, private router: Router) {}

    public version: string;

    ngOnInit() {
        const me = this;
        const versionString = 'Not available';
        this.helpService.getAboutPageText().subscribe((res) => {
            try {
                me.version = res.replace('-SNAPSHOT', '');
            } catch (error) {
                me.version = versionString;
                console.error('Error in coverage url');
                throw error;
            }
        });
    }

    public navigateToPage(endpoint: string): void {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl(endpoint);
    }
}
